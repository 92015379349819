import Card from '../components/Card';
import moment from 'moment';
import { useEffect, useMemo, useReducer } from 'react';
import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Inspector } from '../components/JobCard';
import PageContainer from '../components/PageContainer';
import { Event, Job, InspectionOrder } from '../entities';
import { useMergeState } from '../hooks/useMergeState';
import { getInspectors, getSchedules } from '../services/api-service';

const localizer = momentLocalizer(moment);

interface DashboardState {
  isCalendarLoading?: boolean;
  events: Event[];
  startDate: Date;
  view: View;
}

interface EventDate extends Omit<Event, 'id' | 'title' | 'allDay'> {}

const Dashboard = () => {
  const [{ isCalendarLoading, events, startDate, view }, setMergeState] = useMergeState<DashboardState>({
    events: [],
    view: 'month',
    startDate: new Date()
  });

  const calendarState = useMemo(() => {
    const start = moment(startDate as moment.MomentInput);

    switch (view) {
      case 'month':
        const monthDate = start.startOf('month');
        return { start: monthDate.format('YYYY-MM-DD'), end: monthDate.add(1, 'month').format('YYYY-MM-DD') };
      case 'week':
        const weekDate = start.startOf('week');
        return { start: weekDate.format('YYYY-MM-DD'), end: weekDate.add(1, 'week').format('YYYY-MM-DD') };
      case 'day':
        return {};
      default:
        break;
    }
  }, [startDate, view]);

  useEffect(() => {
    const getEvents = async () => {
      setMergeState({ isCalendarLoading: true });

      if (!calendarState) {
        return;
      }

      try {
        const schedules = await getSchedules(calendarState);
        const inspectors = await getInspectors();
        const events = schedules.map((order: InspectionOrder) => {
          const { inspector: inspectorId, schedule, durationHours, type, status } = order.jobs as unknown as Job;
          const curInspector: Inspector =
            inspectors?.find(({ _id }: Inspector) => _id === (inspectorId as unknown as string)) || {};
          const jobStartDate = moment(schedule);
          return {
            title: `${type} - ${status} - ${curInspector.firstName}`,
            start: jobStartDate.toDate(),
            end: jobStartDate.add(durationHours || 0, 'hours').toDate()
          };
        });
        setMergeState({ events, isCalendarLoading: false });
      } catch (error) {
        console.error(error);
      }
    };
    getEvents();
  }, [calendarState]);

  return (
    <PageContainer title='Dashboard'>
      <Card loading={isCalendarLoading}>
        <Calendar
          localizer={localizer}
          events={events}
          defaultDate={startDate}
          defaultView={view}
          startAccessor='start'
          endAccessor='end'
          style={{ height: 500 }}
          onNavigate={(calendarDate, view) => {
            setMergeState({ startDate: calendarDate, view });
          }}
          onShowMore={() => {
            setMergeState({ view: 'day' });
          }}
        />
      </Card>
    </PageContainer>
  );
};

export default Dashboard;
