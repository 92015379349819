import { Typography } from 'antd';
import { ReactElement, ReactNode } from 'react';
import xw from 'xwind';

type Props = {
  title?: string | ReactElement;
  children?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
};

const { Title } = Typography;

const PageContainer = ({ children, title, header, footer }: Props) => (
  <>
    {header && (
      <div css={xw`h-16 px-8 shadow-lg bg-white right-0 top-0 flex items-center fixed z-10`} style={{ left: 200 }}>
        {header}
      </div>
    )}
    <div
      css={xw`p-4 max-w-screen-2xl mx-auto relative`}
      style={{ marginTop: header ? '3.75rem' : '1rem', marginBottom: footer ? '3.75rem' : '1rem' }}
    >
      {title && <Title>{title}</Title>}
      {children}
    </div>
    {footer && (
      <div css={xw`h-16 px-8 shadow-inner bg-white right-0 bottom-0 flex items-center fixed z-10`} style={{ left: 200 }}>
        {footer}
      </div>
    )}
  </>
);

export default PageContainer;
