import { useCallback, useState } from "react";

export const useMergeState = <T = Record<string, any>>(
  initialValue: T
): [T, <K extends keyof T>(updater: Record<K, T[K]>) => void] => {
  const [state, setState] = useState<T>(initialValue);

  const updater = useCallback(
    <K extends keyof T>(updater: Record<K, T[K]>) => {
      setState((prev) => prev && { ...prev, ...updater });
    },
    [setState]
  );

  return [state, updater];
};
