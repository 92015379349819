import { Card as AntdCard, CardProps } from 'antd';
import { FC } from 'react';
import xw from 'xwind';

const Card: FC<CardProps> = ({ children, ...rest }) => (
  <div css={xw`shadow-lg md:mb-10`}>
    <AntdCard {...rest}>{children}</AntdCard>
  </div>
);

export default Card;
